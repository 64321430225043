// extracted by mini-css-extract-plugin
export var carouselContainer = "D_vw d_w d_H d_bf d_Z";
export var carouselContainerCards = "D_vx D_vw d_w d_H d_bf d_Z";
export var carouselContainerSides = "D_vy d_w d_H d_Z";
export var prevCarouselItem = "D_vz d_w d_H d_0 d_k";
export var prevCarouselItemL = "D_vB D_vz d_w d_H d_0 d_k";
export var moveInFromLeft = "D_vC";
export var prevCarouselItemR = "D_vD D_vz d_w d_H d_0 d_k";
export var moveInFromRight = "D_vF";
export var selectedCarouselItem = "D_vG d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "D_vH D_vG d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "D_vJ D_vG d_w d_H d_Z d_bf";
export var nextCarouselItem = "D_vK d_w d_H d_0 d_k";
export var nextCarouselItemL = "D_vL D_vK d_w d_H d_0 d_k";
export var nextCarouselItemR = "D_vM D_vK d_w d_H d_0 d_k";
export var arrowContainer = "D_vN d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "D_vP D_vN d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "D_vQ D_vP D_vN d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "D_vR D_vN d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "D_vS";
export var nextArrowContainerHidden = "D_vT D_vR D_vN d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "D_kG d_0";
export var prevArrow = "D_vV D_kG d_0";
export var nextArrow = "D_vW D_kG d_0";
export var carouselIndicatorContainer = "D_vX d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "D_vY d_w d_bz d_bF";
export var carouselText = "D_vZ d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "D_v0 D_vZ d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "D_v1 d_b7";
export var carouselIndicator = "D_v2 D_v1 d_b7";
export var carouselIndicatorSelected = "D_v3 D_v1 d_b7";
export var arrowsContainerTopRight = "D_v4 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "D_v5 d_0 d_bl d_bC";
export var arrowsContainerSides = "D_v6 d_0 d_bl d_bC";
export var smallArrowsBase = "D_v7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "D_v8 D_v7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "D_v9 D_v8 D_v7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "D_wb D_v7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "D_wc D_wb D_v7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "D_p9";
export var iconSmall = "D_wd";
export var multipleWrapper = "D_wf d_bC d_bF d_bf";
export var multipleImage = "D_wg d_bC";
export var sidesPrevContainer = "D_wh D_v8 D_v7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "D_wj D_v8 D_v7 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";