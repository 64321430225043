// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "r_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "r_fR d_fR d_bz d_bJ";
export var menuDesign6 = "r_rJ d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "r_rK d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "r_rL d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "r_rM d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "r_rN d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "r_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "r_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "r_rP";
export var navbarItem = "r_nb d_bx";
export var navbarLogoItemWrapper = "r_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "r_rQ d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "r_rR d_gd d_by d_Z d_bs";
export var burgerToggle = "r_rS d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "r_rT d_gd d_by d_Z d_bs";
export var burgerInput = "r_rV d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "r_rW d_f3 d_w d_H";
export var burgerLine = "r_rX d_f1";
export var burgerMenuDesign6 = "r_rY d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "r_rZ d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "r_r0 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "r_r1 d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "r_r2 d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "r_r3 d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "r_r4 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "r_r5 d_bC d_bP";
export var compact = "r_r6";
export var navDivided = "r_r7";
export var staticBurger = "r_r8";
export var menu = "r_r9";
export var navbarDividedLogo = "r_sb";
export var nav = "r_sc";