// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "x_tl d_gS d_cw d_dv";
export var quoteParagraphCenter = "x_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "x_gV d_gV d_cw d_dx";
export var quoteRowLeft = "x_tm d_bG";
export var quoteRowCenter = "x_tn d_bD";
export var quoteRowRight = "x_tp d_bH";
export var quoteWrapper = "x_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "x_gR d_gR";
export var quoteExceptionSmall = "x_rp q_rp";
export var quoteExceptionNormal = "x_rq q_rq";
export var quoteExceptionLarge = "x_rr q_rr";
export var quoteAuthorExceptionSmall = "x_rs q_rs";
export var quoteAuthorExceptionNormal = "x_rt q_rt";
export var quoteAuthorExceptionLarge = "x_rv q_rv";