// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sS d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sT d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sV d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sW d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sX d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sY d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_sZ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_rh q_rh";
export var heroExceptionNormal = "v_rj q_rj";
export var heroExceptionLarge = "v_rk q_rk";
export var Title1Small = "v_qY q_qY q_qs q_qt";
export var Title1Normal = "v_qZ q_qZ q_qs q_qv";
export var Title1Large = "v_q0 q_q0 q_qs q_qw";
export var BodySmall = "v_q7 q_q7 q_qs q_qM";
export var BodyNormal = "v_q8 q_q8 q_qs q_qN";
export var BodyLarge = "v_q9 q_q9 q_qs q_qP";